console.log("Black Pepper Books scripts are being loaded");

function activateHomepageTabs() {
    console.log("Activating homepage tabs");
    const tabs = Array.from(document.getElementsByClassName("filter__btn"));
    const contentDivs = Array.from(document.getElementsByClassName("filter-content"));
    for (const tab of tabs) {
        const category = tab.dataset.category;
        tab.addEventListener("click", (e) => {
            for (const div of contentDivs) {
                if (div.dataset.category === category) {
                    div.style.display = "block";
                } else {
                    div.style.display = "none";
                }
            }
            for (const tab of tabs) {
                if (tab.dataset.category == category) {
                    tab.classList.add("js-checked");
                    tab.setAttribute("aria-selected", "true");
                } else {
                    tab.classList.remove("js-checked");
                    tab.setAttribute("aria-selected", "false");
                }
            }
        });
    }
}

function activateLanguageSelectors() {
    const languageSelectors = Array.from(document.getElementsByClassName("language-selector"));
    for (const selector of languageSelectors) {
        selector.addEventListener("click", (e) => {
            e.preventDefault();
            const csrftoken = Cookies.get('csrftoken');
            const language = e.target.dataset.lang;
            const formData = new FormData();
            formData.append("language", language);
            const response = fetch("/i18n/setlang/", {
                method: "POST",
                body: formData,
                headers: {"X-CSRFToken": csrftoken}
            }).then(() => {
                const refer = e.target.getAttribute("href");
                window.location.href = refer;
            });
        });
    }
}

function activateCountrySelectors() {
    const countrySelectors = Array.from(document.getElementsByClassName("country-selector"));
    for (const selector of countrySelectors) {
        selector.addEventListener("click", (e) => {
            e.preventDefault();
            const csrftoken = Cookies.get('csrftoken');
            const country = e.target.dataset.country;
            const formData = new FormData();
            formData.append("shipping_country", country);
            const response = fetch("/en/set-country/", {
                method: "POST",
                body: formData,
                headers: {"X-CSRFToken": csrftoken}
            }).then(() => {
                window.location.reload();
            });
        });
    }
}

window.activateHomepageTabs = activateHomepageTabs;

activateLanguageSelectors();
activateCountrySelectors();